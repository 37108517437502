
import { Component, Vue } from "vue-property-decorator";
import * as DI from "@/utility/DependencyInjector";
import BackToTop from "@/components/Utility/BackToTop.vue";

@Component({
  components: {
    BackToTop,
  },
})
export default class Quotation extends Vue {
    token = '';
        vapidKeyMap = {
            develop:'BEGS4Dt89plvDLEbAFlpwBYTd3YuU3JmL2CAP5PUBwmT6yEng98msDYjglfupscw8ILFcFB5UjoNTlQf41jiNdQ',
            staging:'BFkGVz_rWwQB75rCP5qIiM5xo2m5YpvsXBZHEwSdxmZ5nGy9YyG5oU8vQCQ2i708I7zAFsAxYadwMQTOCYIjjRk',
            production:'BFyuqAKZKI57k5O2AGVy-AwEmE_OMj7JlN9SOI3ht1VDPxVOyKsuLSqNA09_yASVPpuVtxVzElzjYTVsnqme62I',
        };

		// hook
		async created():Promise<void>{
            if (DI.get('App').auth.currentUser !== undefined) {
                this.$store.commit('setUser', DI.get('App').auth.currentUser.data);

                if('geolocation' in navigator){
                    navigator.geolocation.getCurrentPosition((pos) => {
                        DI.get('App').device.createLocation(pos.coords.latitude, pos.coords.longitude);
                    })
                }

                DI.get('App').auth.onUserAuthLost(async () => {
                    this.$store.commit('clearUser');
                });
            } else {
                alert("已經登出，請重新登入")
                // it prevents from user using admin site without login successfully. 
                console.log("it prevents from user using admin site without login successfully.")
                this.$store.commit('clearUser');

                this.$router.push({
                    path: '/'
                });
                this.$bvModal.show("quotation-login");
            }

        
		}
}
