
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BackToTop extends Vue {
  //data
  scrollNum = 0; //滾動距離
  isTop = false; //是否顯示回到頂部按鈕
  mounted(): void {
    window.addEventListener("scroll", () => {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollNum = top;
      if (top >= 100) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    });
  }

  //methods
  goTop(): void {
    document.documentElement.scrollTop = 0;
  }
}
